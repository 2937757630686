const $ = jQuery = require('jquery');
require('bootstrap/dist/js/bootstrap.bundle');
// require('@fancyapps/ui')
const Fancybox = require('@fancyapps/ui').Fancybox;



// var swiper = new Swiper(".mySwiper", {
//   navigation: {
//     nextEl: ".swiper-button-next",
//     prevEl: ".swiper-button-prev",
//   },
// });

Fancybox.bind("[data-fancybox]", {
  // Your custom options
});


document.addEventListener('DOMContentLoaded', function() {
  // Initialize the swipers
  var swiper = new Swiper(".sermonThumbSwiper", {
    spaceBetween: 10,
    loop: false,
    slidesPerView: 4,
    slidesOffsetAfter:0,
    slidesOffsetBefore: 0,
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    slideToClickedSlide: true,
    threshold: 4,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      200: {
        slidesPerView: 2,
      },
      675: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 3,
      },
      991: {
        slidesPerView: 3.5,
      },
      1024: {
        slidesPerView: 3.5,
      },
      1200: {
        slidesPerView: 4.15,
      },
    },
  });

  var swiper2 = new Swiper(".sermonRecentSwiper", {
    spaceBetween: 10,
    effect: "fade",
    allowTouchMove: false,
    preventClicks: true,
    preventClicksPropagation: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: null,
      prevEl: null,
    },
    thumbs: {
      swiper: swiper,
    },
  });
});




// Fancybox.bind("[data-fancybox]", {
// });

// Mobile Menu button Functionality
// $("#mobile-menu-button").click(function () {
//   $(this).toggleClass('closed');
//   $("#mobile-menu").slideToggle(); // Toggle the visibility of the menu
// });
// $(".jsSearchToggle").click(function () {
//   $('.search-dropdown').toggleClass('open');
// });

$("#mobile-menu-button").click(function () {
  // If the search dropdown is open, close it
  if ($('.search-dropdown').hasClass('open')) {
    $('.search-dropdown').removeClass('open');
  }

  // Toggle the menu and hamburger state
  if ($("#mobile-menu").is(":visible")) {
    // Menu is open, so close it and reset the hamburger
    $("#mobile-menu").slideUp();
    $(this).removeClass('closed'); // Hamburger back to open state
  } else {
    // Menu is closed, so open it and change the hamburger to the close icon
    $("#mobile-menu").slideDown();
    $(this).addClass('closed'); // Hamburger to close state
  }
});

$(".jsSearchToggle").click(function () {
  // If the mobile menu is open, close it and reset the hamburger icon
  if ($("#mobile-menu").is(":visible")) {
    $("#mobile-menu").slideUp();
    $("#mobile-menu-button").removeClass('closed'); // Hamburger back to open state
  }

  // Toggle the search dropdown
  $('.search-dropdown').toggleClass('open');
});



document.addEventListener('DOMContentLoaded', function() {
  // Get all dropdown items
  var dropdownItems = document.querySelectorAll('.has-dropdown');

  // Check if dropdownItems exist
  if (dropdownItems.length > 0) {
    // Add mouseover and mouseout event to each dropdown item
    dropdownItems.forEach(function(item) {
      item.addEventListener('mouseover', function() {
        // Add 'hovered-item' class to the hovered item
        this.classList.add('hovered-item');
      });
      item.addEventListener('mouseout', function() {
        // Remove 'hovered-item' class when the mouse leaves the item
        this.classList.remove('hovered-item');
      });
    });
  }

  // Get the main menu element
  var mainMenu = document.getElementById('main-menu');

  // Check if mainMenu exists
  if (mainMenu) {
    // Add mouseleave event to the main menu
    mainMenu.addEventListener('mouseleave', function() {
      // Find any items with the 'hovered-item' class and remove it
      var hoveredItems = mainMenu.querySelectorAll('.hovered-item');
      if (hoveredItems.length > 0) {
        hoveredItems.forEach(function(hoveredItem) {
          hoveredItem.classList.remove('hovered-item');
        });
      }
    });
  }
});



$(document).ready(function() {
  loadGroups(); // Load initial groups

  // Function to load groups
  function loadGroups() {
    var sortOption = $('.jsGroupSort').val(); // Get current sort option
    var searchTerm = $('.jsGroupSearch').val(); // Get current search ter
    var loadingImage = `<svg class="d-block mb-8" width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19 1.21918C9.17993 1.21918 1.21918 9.17993 1.21918 19C1.21918 28.8201 9.17993 36.7808 19 36.7808C28.8201 36.7808 36.7808 28.8201 36.7808 19C36.7808 9.17993 28.8201 1.21918 19 1.21918ZM0 19C0 8.50659 8.50659 0 19 0C29.4934 0 38 8.50659 38 19C38 29.4934 29.4934 38 19 38C8.50659 38 0 29.4934 0 19ZM18.9985 2.53349L32.933 10.7662V18.5782H32.9338V19.4227H32.933V27.2318L18.9985 35.4668L5.06629 27.2318V19.4227V18.5782V10.7663L18.9985 2.53349ZM6.32694 19.4227H9.28851V18.5782H6.32694V11.5112L18.5778 4.27197V7.85665L9.28868 13.5109V25.3331L18.5778 30.9874V33.7282L6.32694 26.487V19.4227ZM31.6723 11.5112V18.5782H28.7109V13.5109L19.4222 7.85692V4.27361L31.6723 11.5112ZM28.7109 25.3331V19.4227H31.6723V26.487L19.4222 33.7265V30.9871L28.7109 25.3331ZM19.4132 29.555L27.53 24.6144V14.2296L18.9998 9.03733L10.4696 14.2296V24.6144L18.5864 29.555H19.4132Z" fill="#307FF7"/>
      </svg>`;
    $('#groupList').html(`<div class="loading col-lg-12 text-center d-flex flex-column align-items-center justify-content-center fst-italic text-uppercase font-weight-500 text-dark" style="height:150px">${loadingImage} <span class="d-block">Loading...</span></div>`);
    $.ajax({
      url: ajax_params.ajax_url, // WordPress AJAX URL
      method: 'GET',
      data: {
        action: 'get_groups', // Action to trigger the callback function
        sort: sortOption, // Sorting option
        search: searchTerm // Search term
      },
      dataType: 'json',
      success: function (data) {
        console.log('data',data)
        displayGroups(data);
      },
      error: function (xhr, status, error) {
        $('#groupList').html('<li class="error">Error loading data</li>'); // Display error message
      }
    });
  }

  // Function to display groups
  function displayGroups(data) {
    $('#groupList').empty();
    $.each(data, function (index, item) {
      var modalId = 'group-modal-' + item.id;
      var postPermalink = item.permalink;
      var imageHtml = '';
      if (item.group_icon.id != null) {
        imageHtml = `<img src="${item.group_icon.url}" alt="${item.group_icon.alt}" width="22" height="24" class="mb-16">`;
      }
        var groupHtml = `
          <div class="col-xl-3 col-lg-4 col-md-6 col-6">
              <div class="group-card bg-gray card text-center pt-32 h-100 rounded-0 border-0">
                <div class="card-body py-0">
                  ${imageHtml}
                  <h2 class="font-weight-600 mont mb-12">
                    ${item.name}
                  </h2>
                </div>
                <div class="card-footer border-0 bg-gray rounded-0 pb-28 pt-0">
                  <button type="button" class="js-show-modal w-100 text-decoration-none bg-transparent border-0 text-gradient font-weight-700 d-flex align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target="#groupModal" data-group-name="${item.name}" data-group-image="${item.group_icon.url}" data-group-alt="${item.group_icon.alt}" data-group-content="${item.content}">
                    <span>Learn More</span>
                    <svg class="ms-8" width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.84375 0.621094C1.01953 0.445312 1.26562 0.445312 1.44141 0.621094L8.82422 7.96875C8.96484 8.14453 8.96484 8.39062 8.82422 8.56641L1.44141 15.9141C1.26562 16.0898 1.01953 16.0898 0.84375 15.9141L0.140625 15.2461C0 15.0703 0 14.7891 0.140625 14.6484L6.50391 8.25L0.140625 1.88672C0 1.74609 0 1.46484 0.140625 1.28906L0.84375 0.621094Z" fill="url(#paint0_linear_1623_5166)"/>
                      <defs>
                        <linearGradient id="paint0_linear_1623_5166" x1="11" y1="8" x2="-1.57586" y2="4.15737" gradientUnits="userSpaceOnUse">
                          <stop offset="0.045" stop-color="#EE7423"/>
                          <stop offset="0.385" stop-color="#D12FAF"/>
                          <stop offset="0.675" stop-color="#30479E"/>
                          <stop offset="0.93" stop-color="#6DC7A9"/>
                        </linearGradient>
                      </defs>
                    </svg>
                  </button>
                </div>
              </div>
          </div>`;
        $('#groupList').append(groupHtml);
    });
    $('.js-show-modal').on('click', function () {
      var groupName = $(this).data('group-name');
      var groupImage = $(this).data('group-image');
      var groupAlt = $(this).data('group-alt');
      // var groupLink = $(this).data('group-link');
      var groupContent = $(this).data('group-content');

      // Inject data into the modal
      $('#groupModalLabel').text(groupName);
      $('#groupModalImage').attr('src', groupImage).attr('alt', groupAlt);
      $('#groupModalContent').html(groupContent);
      // $('#groupModalLink').attr('href', groupLink);
    });


  }

  // Sort dropdown change event
  $('.jsGroupSort').change(function() {
    loadGroups(); // Reload groups based on new sort option
  });

  // Search input keyup event
  $('.jsGroupSearch').keyup(function() {
    loadGroups(); // Reload groups based on new search term
  });
});




jQuery(document).ready(function($) {
  let page = 2; // Start at the second page
  $('#load-more').on('click', function(e) {
    e.preventDefault();
    let post_type = $(this).data('post-type');
    // Disable the button while loading posts
    $('#load-more').prop('disabled', true);
      $.ajax({
        url: ajax_params.ajax_url,
        type: 'POST',
        data: {
          action: 'load_more_posts',
          page: page,
          post_type: post_type
        },
        beforeSend: function() {
          $('#load-more').text('Loading...');
        },
        success: function(response) {
          if (response.success && response.data.content.trim() != '') {
            if (post_type === 'lighthouse-magazine') {
              $('#magazine-posts').append(response.data.content);
            }
            if (post_type === 'in-the-news') {
              $('#in-the-news-posts').append(response.data.content);
            }
            // Check if there are more posts
            if (response.data.has_more) {
              $('#load-more').prop('disabled', false).html('<span>Load More</span>');
              page++; // Increment the page count
            } else {
              $('#load-more').prop('disabled', true).html('<span>No more posts</span>');
            }
          } else {
            $('#load-more').prop('disabled', true).html('<span>No more posts</span>')
          }
        },
        error: function() {
          $('#load-more').prop('disabled', false).html('<span>Load More</span>');
        }
      });
  });
});

